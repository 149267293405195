import Vue from 'vue';
import App from './App.vue';

import 'fullpage.js/vendors/scrolloverflow'
import VueFullPage from 'vue-fullpage.js/dist/vue-fullpage.min'
Vue.use(VueFullPage);

Vue.config.productionTip = process.env.NODE_ENV === 'development';

window.console.error = function () {};

new Vue({
    render: h => h(App),
}).$mount('#app');
