<template>
  <div class="nav-cntr">
    <div class="nav-content">
      <ul class="nav-list">
        <li class="nav-item" @click="goAnchor('logo')">
          <div class="logo"></div>
        </li>
        <li
          class="nav-item item"
          :class="`item-${item.k}`"
          v-for="(item, index) in nav"
          :key="index"
          @click="goAnchor(item.k)"
          >
          {{item.v}}
        </li>
      </ul>
      <!-- <div class="nav-item author"  >
        <span class="autor-item" @click="authorEve('author')" title="成为作家吧～"> 作家入口</span>
        <span class="autor-item" @click="authorEve('welfare')" title="查收您的福利～"> 作家福利</span>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
    data () {
        return {
            nav: [
                {
                    k: 'reader',
                    v: '正版阅读'
                },
                {
                    k: 'content',
                    v: '海量内容'
                },
                {
                    k: 'appDownload',
                    v: 'APP下载'
                },
                {
                    k: 'about',
                    v: '关于我们'
                }
            ]
        }
    },
    methods: {
        // 锚点.
        goAnchor (type) {
            console.log(type)
            const domTop = document.getElementById(type).offsetTop - 100;
            window.scrollTo(0, domTop);
        },
        // 成为作家吧.
        authorEve (type) {
            let url = `https://www.huanduwenxue.com/#/login?from=midureader`
            if (type == 'welfare') {
                url = 'https://m.midukanshu.com/huandu/welfare_index.html?from=midureader'
            }
            window.open(url)
        }
    }
}
</script>
<style lang="less" scoped>
.nav-cntr {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height:100px;
  background:rgba(35,40,45,1);
  font-family:PingFangSC-Semibold,PingFang SC;
  z-index: 9;
  ul,li{
    padding: 0;
    margin: 0;
  }
  .nav-content {
    position: relative;
    margin: 0 auto;
    width: 1000px;
    line-height: 100px;
    box-sizing: border-box;
    .logo{
      width: 175px;
      height: 60px;
      background: url("../assets/logo.png") no-repeat center center;
      background-size: contain;
    }
    .nav-item {
      display: inline-block;
      font-size:18px;
      font-weight:600;
      color:rgba(255,255,255,1);
      line-height:25px;
      vertical-align: middle;
      cursor: pointer;
      &.item-reader {
        margin-left: 75px;
      }
      &.item-content {
        margin-left: 59px;
      }
      &.item-appDownload {
        margin-left: 64px;
      }
      &.item-about {
        margin-left: 63px;
      }
      &.author {
        position: absolute;
        top: 0;
        right: 0;
        line-height: 100px;
        >span {
          margin-left: 32px;
        }
      }
    }
  }
}
</style>
