<template>
  <div class="content-cntr">
    <section class="sec-item item-logo" id="logo">
      <div class="sec-title">筑梦奔跑 字然有趣</div>
      <div class="sec-des">让所有人爱上阅读</div>
    </section>
    <section class="sec-item item-reader" id="reader">
      <div class="sec-title">免费正版阅读</div>
      <div class="sec-des">
        米读小说是国内首创的免费网络文学阅读平台，2018年5月米读小说正式上线，秉承变革<br />
        创新精神，在业内首创电子小说免费阅读的商业模式，为用户提供优质的网络文学内容和
        <br/>个性化、智能化服务。
      </div>
      <div class="sec-online">
        <div class="online-item" v-for="(item, index) in online" :key="index">
          <div class="online-title" v-text="item.k"></div>
          <div class="online-intro" v-html="item.v"></div>
        </div>
      </div>
    </section>

    <section class="sec-item item-content" id="content">
      <div class="sec-title">浩如烟海 字若星河</div>
      <div class="sec-des">
        <p>米读小说与上百家内容提供商合作，同时深入挖掘优质的原创内容</p>
        <p>现已经形成拥有全分类、数万本书籍的庞大书库。</p>
      </div>
      <div class="sec-cover">
        <div class="merge"></div>
        <div class="cover marqueen">
          <img src="../assets/3-1.png" alt="">
          <img src="../assets/3-2.png" alt="">
          <img src="../assets/3-3.png" alt="">
          <img src="../assets/3-4.png" alt="">
        </div>
        <div class="cover marqueen-2">
          <img src="../assets/3-2.png" alt="">
          <img src="../assets/3-3.png" alt="">
          <img src="../assets/3-4.png" alt="">
          <img src="../assets/3-1.png" alt="">
        </div>
        <div class="cover marqueen">
          <img src="../assets/3-3.png" alt="">
          <img src="../assets/3-4.png" alt="">
          <img src="../assets/3-1.png" alt="">
          <img src="../assets/3-2.png" alt="">
        </div>
        <div class="cover marqueen-2">
          <img src="../assets/3-4.png" alt="">
          <img src="../assets/3-1.png" alt="">
          <img src="../assets/3-2.png" alt="">
          <img src="../assets/3-3.png" alt="">
        </div>
      </div>
    </section>
    <section class="sec-item item-app" id="appDownload">
      <div class="sec-title">下载米读小说</div>
      <div class="sec-box">
        <div class="sec-lf">
          <div class="midu">
            <div class="midu-qrcode"></div>
            <div class="midu-title">米读小说</div>
          </div>
<!--          <div class="midu jisu">-->
<!--            <div class="midu-qrcode jisu"></div>-->
<!--            <div class="midu-title">米读小说极速版</div>-->
<!--          </div>-->
        </div>
        <div class="banner"></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
    data () {
        return {
            online: [
                {
                    k: '2018.5',
                    v: '米读双端<br />正式上线'
                },
                {
                    k: '2018.12',
                    v: '平均日活<br />突破500万'
                },
                {
                    k: '2019.3',
                    v: '平均日活<br />突破622万'
                },
                {
                    k: '2019.7',
                    v: '极速版上线'
                },
                {
                    k: '2021.3',
                    v: 'C轮融资<br />1.1亿美金'
                }
            ]
        }
    }
}
</script>

<style lang="less" scoped>
body {
  padding: 0;
  margin: 0;
}
.content-cntr {
  padding-top: 100px;
  min-width: 1000px;
  background: #999;
  .sec-item {
    position: relative;
    text-align: center;
    overflow: hidden;
    &.item-logo {
      height: 696px;
      background: url("../assets/1.jpg") repeat-x center center;
      background-size: 1920px 696px;
      .sec-title {
        margin-top: 232px;
        font-size:60px;
        font-family:SourceHanSerifSC-Bold,SourceHanSerifSC;
        font-weight:bold;
        color:rgba(255,255,255,1);
        line-height:86px;
      }
      .sec-des {
        margin-top: 13px;
        font-size:40px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(255,255,255,1);
        line-height:56px;
      }
    }
    &.item-reader {
      background: #fff;
      height: 640px;
      .sec-title {
        margin-top: 80px;
        font-size:48px;
        font-family:SourceHanSerifSC-Bold,SourceHanSerifSC;
        font-weight:bold;
        color:rgba(48,55,65,1);
        line-height:69px;
      }
      .sec-des {
        margin-top: 60px;
        font-size:24px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(93,100,110,1);
        line-height:33px;
      }
      .sec-online {
        margin-top: 100px;
        text-align: center;
        .online-item {
          position: relative;
          padding-left: 20px;
          display: inline-block;
          width: 198px;
          height: 100px;
          vertical-align: middle;
          text-align: left;
          box-sizing: border-box;
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width:2px;
            height:100px;
            background:linear-gradient(315deg,rgba(255,255,255,0) 0%,rgba(248,95,95,1) 76%);
            opacity:0.41;
          }
          &:last-child {
            width: 116px;
          }
          .online-title {
            font-size:36px;
            font-family:DINCondensed-Bold,DINCondensed;
            font-weight:bold;
            color:rgba(77,138,233,1);
            line-height:43px;
          }
          .online-intro {
            margin-top: 8px;
            font-size:18px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:400;
            color:rgba(48,55,65,1);
            line-height:25px;
          }
        }
      }
    }
    &.item-content {
      height: 640px;
      .sec-title {
        position: relative;
        top: 220px;
        font-size:48px;
        font-family:SourceHanSerifSC-Bold,SourceHanSerifSC;
        font-weight:bold;
        color:rgba(255,255,255,1);
        line-height:69px;
        z-index: 3;
      }
      .sec-des {
        position: relative;
        top: 260px;
        font-size:24px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(255,255,255,1);
        line-height:33px;
        z-index: 3;
      }
      .sec-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 640px;
        z-index: 0;
        .merge {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(31, 33, 35, 0.87);
          z-index: 1;
        }
        .cover {
          width: 100%;
          height: 160px;
          &.marqueen {
            animation: marqueen 77s infinite linear;
            white-space: nowrap;
            img {
              width: 1440px;
              height: 161px;
              padding: 0;
              margin: 0;
            }
          }
          &.marqueen-2 {
            animation: marqueen 88s infinite linear;
            white-space: nowrap;
            img {
              // float: left;
              width: 1440px;
              height: 161px;
              padding: 0;
              margin: 0;
            }
          }
        }
      }
    }
    &.item-app {
      height: 640px;
      background: url("../assets/4.png") repeat-x center center;
      background-color: #fff;
      background-size: 954px 1079px;
      .sec-title {
        margin-top: 80px;
        font-size:48px;
        font-family:SourceHanSerifSC-Bold,SourceHanSerifSC;
        font-weight:bold;
        color:rgba(48,55,65,1);
        line-height:69px;
      }
      .sec-box {
        position: relative;
        width: 1000px;
        height: 455px;
        margin: 36px auto 0;
        text-align: left;
        .sec-lf {
          .midu {
              position: absolute;
              top: 50%;
              transform: translate(0, -50%);
            &.jisu {
              margin-top: 54px;
            }
            .midu-qrcode {
              width: 140px;
              height: 140px;
              background: url("//img.midukanshu.com/browser/h5/activity/qrcode/midu-2021-11-10.png") no-repeat center center;
              background-size: contain;
              &.jisu {
                background: url("//img.midukanshu.com/browser/h5/activity/qrcode/qrcode_midu_speed.png") no-repeat center center;
                background-size: contain;
              }
            }
            .midu-title {
              width: 140px;
              margin-top: 8px;
              font-size:18px;
              font-family:PingFangSC-Regular,PingFang SC;
              font-weight:400;
              color:rgba(48,55,65,1);
              line-height:25px;
              text-align: center;
            }
          }
        }
        .banner {
          position: absolute;
          right: -20px;
          top: 0;
          width: 640px;
          height: 455px;
          background: url("../assets/4-1.png") no-repeat center center;
          background-size: contain;
        }
      }
    }
  }
}
@keyframes marqueen {
  0% {
      transform: translate(0, 0);
  }
  100% {
      transform: translate(-100%, 0)
  }
}
</style>
