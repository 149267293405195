<template>
  <div class="footer-cntr" id="about">
    <div class="footer">
      <div class="logo"></div>
      <div class="info">
        <p>上海大犀角信息科技有限公司</p>
        <p class="address">地址：上海市嘉定区真南路4268号2幢JT7450室<br>邮编：200000<br/>电话：021-68782586</p>
      </div>
    </div>
    <div class="copyright">
      <div class="copyright-top">
        <a class="gov-info-top" target="_blank" href="https://img.midukanshu.com/midu_reader/chubanwu.png"> 出版物经营许可证</a>
        <a class="gov-info-top" target="_blank" href="https://img.midukanshu.com/midu_reader/guangbo.png">广播电视节目制作经营许可证</a>
        <a class="gov-info-top" target="_blank" href="https://img.midukanshu.com/midu_reader/wangluo.jpg">网络文化经营许可证</a>
        <a class="gov-info-top" target="_blank" href="https://img.midukanshu.com/browser/h5/protocol/android_midu_service_agreement.html">用户协议</a>
        <a class="gov-info-top" target="_blank" href="https://img.midukanshu.com/browser/h5/protocol/android_midu_privacy_agreement.html">隐私政策</a>
        <a class="gov-info-top">举报电话：0553-8820057</a>
      </div>
      <div class="copyright-info">
        <div class="copyright-info-left">
          <a class="gov-info" href="http://www.shjbzx.cn" target="_blank" title="上海互联网举报中心">
          <span class="icon-gov"></span>
          <span>上海互联网举报中心</span>
        </a>
        <a class="gov-info" href="http://www.12377.cn" target="_blank" title="上海互联网举报中心">
          <span class="icon-gov-2"></span>
          <span>网上有害信息举报专区</span>
        </a>
        </div>
        <a class="icp gov-info" href="https://beian.miit.gov.cn/#/Integrated/index">沪ICP备18045778号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
  .footer-cntr {
    position: relative;
    // height:282px;
    background:rgba(48,55,65,1);
    .footer {
      position: relative;
      margin: 0 auto;
      max-width: 1000px;
      padding-bottom:30px;
      .logo{
        position: absolute;
        top: 50px;
        left: 0;
        width: 92px;
        height: 61px;
        background: url("../assets/logo-2.png") no-repeat center top;
        background-size: contain;
      }
      .info {
        padding-top:50px;
        text-align: right;
        font-size:18px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(133,140,150,1);
        line-height:25px;
        p {
          margin: 0;
          &.address {
            margin-top: 23px;
          }
        }
      }
    }
    .copyright-top{
        display:flex;
        flex-wrap: wrap;
        // height:60px;
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom:30px;
        background:rgba(41,44,48,1);
      }
      .gov-info-top {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height:30px;
        color: #858c96;
        text-decoration: none;
        margin-right:24px;
      }
    .copyright {
      box-sizing: border-box;
      // position: absolute;
      // left: 0;
      // bottom: 0;
      width: 100%;
      padding:40px 160px;
      // text-align: center;
      // height:79px;
      // line-height: 79px;
      background:rgba(41,44,48,1);
      font-size:18px;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color:rgba(133,140,150,1);
      &-info {
        display:flex;
        justify-content: space-between;
        max-width: 1000px;
        margin: 0 auto;
        // margin: 0 auto;
        // width: 100%;
        max-width: 1000px;
      }
      .icp {
        // float: right;
      }
      .gov-info {
        // float: left;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #858c96;
        text-decoration: none;
        .icon-gov {
          display: inline-block;
          margin-right: 5px;
          width: 36px;
          height: 36px;
          background: url("../assets/gov-info.png") no-repeat center top;
          background-size: contain;
          overflow: hidden;
          vertical-align: middle;
        }
        .icon-gov-2 {
          margin-left: 15px;
          display: inline-block;
          margin-right: 5px;
          width: 36px;
          height: 36px;
          background: url("../assets/icon_gov.png") no-repeat center top;
          background-size: contain;
          overflow: hidden;
          vertical-align: middle;
        }
      }
    }
  }
</style>
