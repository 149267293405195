<template>
  <div id="app">
    <nav-cntr></nav-cntr>
    <content-cntr></content-cntr>
    <footer-ctnr></footer-ctnr>
  </div>
</template>

<script>
import Nav from './components/nav'
import Footer from './components/footer'
import Content from './components/content'
export default {
    components: {
        'nav-cntr': Nav,
        'content-cntr': Content,
        'footer-ctnr': Footer
    },
    data () {
        return {}
    },
    mounted () {
        this.$nextTick(() => {
            console.log('Zzz')
        })
    }
}
</script>
<style lang="less">

body {
  margin: 0;
  #app {
    padding: 0;
    margin: 0;
  }
}
</style>
